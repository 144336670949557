@import "~@fontsource/poppins/index.css";

#otherPeopleTitle{
    font-family: "Poppins";
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 4%;
}

#dropDownTitle{
    font-family: "Poppins";
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
}

#accordionExample{
    width: 1248px;
    border-radius: 16px;
    border: 1px;
    border-color: #D0D0D0;
    margin-bottom: 3%;
}

#dropDownTitle[aria-expanded="true"] {
    color: #197E89; 
    background-color: initial;
}

#learnMore{
    font-family: "Poppins";
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #197E89;
    font-style: italic;
    cursor: pointer;
}

#bodyTitle{
    font-family: "Poppins";
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #00000099;
    font-style: italic;
}

@media (max-width: 678px){
  #accordionExample{
    max-width: 350px;
    margin-left: 6%;
  }
  #whatOtherContainer{
    margin-top: 10%;
    margin-bottom: 10%;
  }
}



