.unique-center {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

 #main{
  overflow-y: hidden;
  overflow-x: hidden;
 }



 


 