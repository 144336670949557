@import "~@fontsource/poppins/index.css";

#footerContainer{
    background-color: #F3F6FB;
    margin-bottom: -4%;
}

footer.footer-border {
    border-bottom: none;
 }
 
#companyNameDiv {
    margin-left: 40%;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
 }

 #navItem{
    font-family: "Poppins";
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #00000099;
   
 }

 @media(max-width:678px){
    #companyNameDiv{
       margin-left: 7%;
    }
 }


  
  
  
  