@import "~@fontsource/poppins/index.css";

#star{
    width: 150px;
    height: 25px;
}

#teacherCard {
    width: 420px;
    height: 310px;
    border: none;
    box-shadow: 1px 3px 5px 3px rgba(0, 0, 0, 0.1); 
}


#teacherName{
    font-family: "Poppins";
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
}

#subject{
    font-family: "Poppins";
    font-weight: 400;
    font-style: italic;
    font-size: 14px;
    line-height: 21px;
    color: #00000099; 
}

#teacherText{
    font-family: "Poppins";
    font-weight: 400;
    font-style: italic;
    font-size: 16px;
    line-height: 28px;
    color: #00000099; 
}

#WhyTeacherTitle{
    font-family: "Poppins";
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #000000; 
    text-align: center;
    margin-bottom: 3%;
}

#TeacherContainer{
    margin-top: 3%;
    position:-webkit-sticky
}

@media (max-width:678px){
    #TeacherContainer{
        margin-top: 55%;
    }

    #teacherCard{
       margin-top: 5%;
       margin-left: 3%;
       width: 380px;
       height: 350px;
    }
}

/* Auto-scroll animation */
@keyframes scroll {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
  }
  
  .scrolling-wrapper {
    display: flex;
    flex-direction: row;
    animation: scroll 20s linear infinite;
    position:-webkit-sticky;
    width: 100%;
  }

  #teacherLogo{
    width: 58px;
    height: 58px;
    border-radius: 50%
  }

