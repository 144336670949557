@import "~@fontsource/poppins/index.css";

#FedesImg{
    width: 508px;
    height: 271px;
    margin-left: 136px;
}

#whatIsContainer {
    background-color: #F3F6FB;
    height: 484px;
  }

#whatTitle{
    text-align: center;
    margin-top: 2%;
    margin-bottom: 3%;
    text-align: center;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    padding-top: 2%;
}

#whatIsText{
    width: 562px;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: #00000099;
}

#imgRow {
    display: flex; 
    align-items: center; 
   
}

#whatCol1 {
    flex: 1; 
}

#whatCol2 {
  flex: 1; 
  margin-left: -7%;
}

@media (max-width:678px){
    #FedesImgDiv{
        margin-left: -25%;
    }

    #FedesImg{
        width: 350px;
        height: 200px;
    }

    #whatCol2 {
        margin-bottom: 10px; 
        margin-left: 2%;
        padding-top: 10%;
      }
      .teacherName, .subject {
        font-size: 14px;
      }
      #whatIsContainer {
        height: 650px;
        margin-bottom: 6%;
        margin-top: 10%;
      }
     
      #whatIsText{
        max-width: 390px;
        margin-left:3%;
        
      }
}


@media (min-width:1920px){
  #whatCol1 {
    margin-left: 15%; 
  }
  #FedesImgDiv{
    margin-left: 20%;
  }
}