@import "~@fontsource/poppins/index.css";

#cardLogo{
   width: 72px;
   height: 62px;
   margin-right: 4%;
 
}

#cardContent {
    display: flex;
    align-items: center; 
  }
  
  .card-text {
    flex-grow: 1; 
  }

  
  #Unique{
    width: 454px;
    margin: 3%;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.112);
    border: none;
    border-radius: 12px;
  }

  #UniqueTitle{
    text-align: center;
    margin-top: 2%;
    margin-bottom: 3%;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }

  @media (max-width: 678px){
    #Unique{
      max-width: 350px;
      margin-left: 8%;
    }
  }
