@import "~@fontsource/poppins/index.css";

.video-container {
    text-align: center;
    margin: 40px 0;
  }
  
  #howItwork {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #053345;
    margin-bottom: 3%;
  }
  
  .video-wrapper {
    display: flex;
    justify-content: center;
  }
  
  .video-wrapper iframe {
    width: 100%;
    max-width: 1248px;
    height: 628px;
    border-radius: 18px;
    border: none;
  }
  