@import "~@fontsource/poppins/index.css";

#headerContainer {
  background-color: #f3f6fb;
  height: 826px;
}

#headerTitle {
  font-family: "Poppins";
  font-weight: 800;
  font-size: 62px;
  line-height: 66px;
  color: #053345;
}

#Emotion {
  font-family: "Poppins";
  font-weight: 900;
  font-size: 62px;
  line-height: 66px;
  color: #197e89;
}

#StartBtn {
  width: auto;
  height: 44px;
  border-radius: 10px;
  background-color: #053345;
  color: white;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

#learnBtn {
  width: auto;
  height: 44px;
  border-radius: 10px;
  border: 1;
  border-color: #053345;
  color: #053345;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

#buttonSection {
  justify-content: center;
}

#reportImgDiv {
  display: flex;
  justify-content: center;
  margin-top: 0%;
}

#reportImg {
  width: 887px;
  height: auto;
}

#logo {
  max-width: 92px;
  max-height: 87px;
  margin-left: 100%;
}

@media (max-width: 768px) {
  #reportImg {
    max-width: 80%;
    margin-top: 1%;
  }
  #headerContainer {
    height: 100%;
  }
}

#navName {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #00000099;
}

#navNameTeacher {
  border: 1px solid #053345;
  border-radius: 8px;
  width: 100%;
  height: 34px;
  padding: 5px 10px;
}

@media (min-width: 1920px) {
  #reportImgDiv {
    margin-top: 0%;
  }
}

#subHeading1 {
  font: "Poppins";
  font-weight: 400;
  font-style: 18px;
  line-height: 27px;
  text-align: Center;
  color: #00000099;
}

#subHeading2 {
  font: Poppins;
  font-weight: 400;
  font-style: 18px;
  line-height: 27px;

  color: #00000099;
}

#subHeadingDiv {
  margin-top: -3%;
  margin-bottom: 2%;
}

#fedesLogo {
  width: 92px;
  height: 87px;
  margin-left: 94px;
  margin-top: 30px;
}

#signupTeacher {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #053345;
}

#list {
  gap: 20px;
}

@media (max-width: 678px) {
  #list {
    gap: 2px;
  }
  #fedesLogoDiv {
    margin-left: 17%;
  }
}

.centered-text-border {
  display: inline-block;
  padding: 0px 15px;
  text-align: center;
  color: #053345;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
}

@media (max-width: 678px) {
  #subHeadingDiv {
    flex-direction: row;
  }

  #buttonSection {
    margin-left: -10%;
  }

  #buttonSection .col-md-2 {
    flex: 0 0 auto;
    width: 27%;
  }

  #new {
    margin-left: 2%;
  }

  #Emotion {
    margin-left: -2%;
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #197e89;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease-in-out;
  animation: pulse 2s infinite;
}

.scroll-to-top:hover {
  background-color: #053345;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

#clickHere {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #00000099;
}
