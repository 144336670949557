#subHeaderContainer {
    background-color: #f3f6fb;
    height: 150px;
  }

  @media (max-width: 768px) {
    
    #subHeaderContainer {
      height: 100%;
    }
  }