@import "~@fontsource/poppins/index.css";

#contactUsContainer{
    background-color: #F3F6FB;
    height: 700px;
}

#contactImg{
    max-width: auto;
    height: auto;
    margin-left: 106px;
}

#contactUsTitle{
    text-align: center;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-top: 3%;
    padding-top: 2%;
}

#contactUsSubTitle{
    text-align: center;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 18px;
    line-height:27px;
    color: #00000099;
    margin-bottom: 3%;
}

#sendBtn{
    background-color: #053345;
    width: 100%;
    height: 44px;
    color: white;
    border-radius: 10px;
}

#WhatsappBtn{
    background-color: #25D366;
    width: 100%;
    height: 44px;
    color: white;
    border-radius: 10px;
}

#orDiv{
    text-align: center;
    margin-top: 3%;
    margin-bottom: 3%;
}

@media (max-width:678px){
    #contactImg{
        margin-left: 20%;
        max-width: 300px;
        max-height: 220px;
        margin-bottom: 5%;
    }

    #sendBtn{
        max-width: 100%;
    }

    
    #WhatsappBtn{
        max-width: 100%;
    }

    #contact-form{
      margin-left: 3.5%;
    }
   
}

#form_name{
    max-width: 100%;
    height: 45px;
}

#form_name1{
    max-width: 100%;
   
}

@media (max-width:678px){
    #form_name{
        max-width: auto;
    }

    #contactUsContainer{
        height: auto;
        margin-bottom: -35%;
        padding-bottom: 10%;
    }
}

@media (min-width:1800px){
    #contactImgDiv{
        margin-left: 15%;
    }
    #contactUsContainer{
        padding-bottom: 40%;
    }
}


